<template>
  <v-card outlined color="background">
    <v-stepper :value="currentStepIndex" flat style="z-index: 200">
      <v-stepper-header>
        <template v-for="(item, index) in submissionSteps">
          <v-stepper-step
            :key="item.id"
            :complete="item.completed"
            :step="index + 1"
            :complete-icon="mdiCheck"
            :color="item.completed ? 'button' : 'buttonSecondary darken-2'"
          >
            {{ item.text }}
          </v-stepper-step>
          <v-divider
            v-if="index < submissionSteps.length - 1"
            :key="`${item.id}_divider`"
          />
        </template>
      </v-stepper-header>
    </v-stepper>
  </v-card>
</template>

<script>
import OrderSubmissionStatus from '@/shared/types/OrderSubmissionStatus';
import SubmissionDocType from '@/shared/types/SubmissionDocType';
import { mdiCheck } from '@mdi/js';
export default {
  name: 'SubmissionWorkflowStepper',
  props: {
    submission: {
      type: Object
    }
  },
  computed: {
    currentStepIndex() {
      const index = this.submissionSteps.findIndex((item) => item.isCurrent);
      return index + 1;
    },

    submissionWorkflowSteps() {
      if (
        this.submission?.documentType === SubmissionDocType.RECEIPT ||
        (this.submission?.parentSubmissionId &&
          SubmissionDocType.INVOICE_RECEIPT)
      ) {
        return this.submission?.relatedOrder?.accountManagementWorkflowSteps;
      } else {
        return this.submission?.relatedOrder?.orderSubmissionWorkflowSteps;
      }
    },

    submissionSteps() {
      const steps = [];

      steps.push({
        id: 'step1',
        text: this.$t('customer.vendorSubmissions.submissionSteps.step1'),
        isCurrent: false,
        completed: true
      });

      steps.push({
        id: 'step2',
        text: this.$t('customer.vendorSubmissions.submissionSteps.step2'),
        isCurrent:
          this.submission?.status === OrderSubmissionStatus.NEW ||
          this.submission?.status === OrderSubmissionStatus.NEW_PREDICTED,
        completed: [
          OrderSubmissionStatus.PENDING_APPROVAL,
          OrderSubmissionStatus.APPROVED
        ].includes(this.submission?.status)
      });

      if (this.submissionWorkflowSteps) {
        // add submission workflow approval steps
        for (const step of this.submissionWorkflowSteps) {
          steps.push({
            id: step._id,
            text: step.stepTitle,
            isCurrent:
              this.submission?.currentWorkflowApprovalStepId ===
              step.accountWorkflowStepId,
            completed: this.submission?.completedWorkflowStepIds?.includes(
              step.accountWorkflowStepId
            )
          });
        }
      }

      steps.push({
        id: 'finish',
        text: this.$t('customer.vendorSubmissions.submissionSteps.step5'),
        isCurrent: false,
        completed: this.submission?.status === OrderSubmissionStatus.APPROVED
      });

      return steps;
    }
  },
  data() {
    return {
      mdiCheck
    };
  }
};
</script>

<style lang="scss" scoped></style>
